export default grapesjs.plugins.add('HomeSliderItem', (editor, options) => {
	// This area is commented out so that the slider item component is not displayed in the tool list.
	//
	// editor.BlockManager.add('HomeSliderItem', {
	// 	category: 'Slider',
	// 	label: 'Home Slider Item',
	// 	attributes: { class: 'gjs-fonts gjs-f-b1' },
	// 	content: `<div class="w-100 mb-5 home-slider-container" data-gjs-type="HomeSliderItem" data-initial-state="true">
	// 		Component preview: configure your home slider item component!
	// 		<br /> 
	// 		<img class="img-fluid" src="` + CCRZ.pagevars.themeBaseURL + `images/ccc-preview-image-text.png" style="margin: auto; display: block;" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-badgable="false" data-gjs-stylable="false" data-gjs-highlightable="false" data-gjs-copyable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-layerable="false" data-gjs-selectable="false" data-gjs-hoverable="false" />
	// 	</div>`
	// });

	// Get DomComponents module
	var comps = editor.DomComponents;
	
	// Get the model and the view from the default Component type
	var defaultType = comps.getType('default');
	var defaultModel = defaultType.model;
	var defaultView = defaultType.view;

	// start configuration Teaser-Tile-Big
	comps.addType('HomeSliderItem', {
		// Define the Model
		model: defaultModel.extend({
			// Extend default properties
			defaults: Object.assign(
				{},
				defaultModel.prototype.defaults, {
					// Can't drop other elements inside it
					droppable: false,
					// Traits (Settings)
					traits: [
						{
							type: 'checkbox', // Type of the trait
							label: 'Is image linked?', // The label you will see in Settings
							name: 'isImgLink', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Image link', // The label you will see in Settings
							name: 'imgLink', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Image path', // The label you will see in Settings
							name: 'imgSrc', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Image title', // The label you will see in Settings
							name: 'imgTitle', // The name of the attribute/property to use on component
						},
						{
							type: 'checkbox', // Type of the trait
							label: 'Show info box?', // The label you will see in Settings
							name: 'isInfoboxShown', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Headline first', // The label you will see in Settings
							name: 'headlineFirst', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Headline second', // The label you will see in Settings
							name: 'headlineSecond', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Content text', // The label you will see in Settings
							name: 'contentText', // The name of the attribute/property to use on component
						}, {
							type: 'text', // Type of the trait
							label: 'Button text', // The label you will see in Settings
							name: 'btnPriTxt', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Button link', // The label you will see in Settings
							name: 'btnPriHref', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Is Visible From', // The label you will see in Settings
							name: 'cccStartDate', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Is Visible To', // The label you will see in Settings
							name: 'cccEndDate', // The name of the attribute/property to use on component
						}
					],
					attributes: {
						isImgLink: false,
						imgLink: '',
						imgSrc: '',
						imgTitle: '',
						isInfoboxShown: true,
						headlineFirst: '',
						headlineSecond: '',
						contentText: '',
						btnPriTxt: '',
						btnPriHref: '',
						cccStartDate: '',
						cccEndDate: ''
					}
				}
			),
			init() {
				this.on('change:attributes:isImgLink', this.handleTraitChange);
				this.on('change:attributes:imgLink', this.handleTraitChange);
				this.on('change:attributes:imgSrc', this.handleTraitChange);
				this.on('change:attributes:imgTitle', this.handleTraitChange);
				this.on('change:attributes:isInfoboxShown', this.handleTraitChange);
				this.on('change:attributes:headlineFirst', this.handleTraitChange);
				this.on('change:attributes:headlineSecond', this.handleTraitChange);
				this.on('change:attributes:contentText', this.handleTraitChange);
				this.on('change:attributes:btnPriTxt', this.handleTraitChange);
				this.on('change:attributes:btnPriHref', this.handleTraitChange);
				this.on('change:attributes:cccStartDate', this.handleTraitChange);
				this.on('change:attributes:cccEndDate', this.handleTraitChange);
			},
			handleTraitChange() {
				this.attributes.content = this.generateHTML(false);
				this.view.el.innerHTML = this.generateHTML(false);
				$(this.view.el).removeAttr('data-initial-state');
				delete this.view.attr['data-initial-state'];
				delete this.attributes.attributes['data-initial-state'];
			},
			toHTML: function () {
				return this.generateHTML(true);
			},
			generateHTML(includeParentDOMElement) {
				let imgSrc = "/closedeshop/resource/HDU_EShop_Theme/images/no-image.jpg";	// define fallback image

				if (this.getAttributes().imgSrc) {
					imgSrc = this.getAttributes().imgSrc;
				}

				if (typeof includeParentDOMElement == 'undefined') {
					includeParentDOMElement = false;
				}

				var elementString = '';

				if (includeParentDOMElement) {
					elementString = elementString + '<div class="item slider-position" data-gjs-type="HomeSliderItem">';
				}

				elementString += `
				<div class="custom"	>`; 
					if (this.getAttributes().isImgLink) {
						elementString += `
						<a href="` + this.getAttributes().imgLink + `" class="splashPromoLink" data-promo="HomepageSlider Packaging">
							<img src="` + imgSrc + `" alt="` + this.getAttributes().imgTitle + `" class="center-block slider-image-size">
						</a>`;
					} else {
						elementString += `
						<img src="` + imgSrc + `" alt="` + this.getAttributes().imgTitle + `" class="center-block slider-image-size">`;
					}
				elementString += `
				</div>`;
				if (this.getAttributes().isInfoboxShown) {
					elementString += `
					<div class="col-xs-12 col-xm-12 slider-textbox slider-textbox-position">`;
						if (this.getAttributes().headlineFirst) {
							elementString += `
							<div>
								<p class="slider-firstheader">` + this.getAttributes().headlineFirst + `</p>
							</div>`;
						}

						if (this.getAttributes().headlineSecond) {
							elementString += `
							<div>
								<p class="slider-secondheader">` + this.getAttributes().headlineSecond + `</p>
							</div>`;
						}

						elementString += `
						<div class="slider-underline"></div>`;

						if (this.getAttributes().contentText) {
							elementString += `
							<div class="slider-content">
								<p>` + this.getAttributes().contentText + `</p>
							</div>`;
						}

						if (this.getAttributes().btnPriTxt && this.getAttributes().btnPriHref) {
							elementString += `
							<a href="` + this.getAttributes().btnPriHref + `" class="slider-button-text btn btn-lg btn-primary splashPromoLink" data-promo="HomepageSlider Packaging">` + this.getAttributes().btnPriTxt + `</a>`;
						}
					elementString += `
					</div>`;
				}

				if (includeParentDOMElement) {
					elementString += '</div>';
				}

                console.log({component: 'HomeSliderItem', elementString: elementString});
				return elementString;
			}
		},
			// The second argument of .extend are static methods and we'll put inside our
			// isComponent() method. As you're putting a new Component type on top of the stack,
			// not declaring isComponent() might probably break stuff, especially if you extend
			// the default one.
			{
				isComponent(el) {
					if (el.getAttribute && el.getAttribute('data-gjs-type') == 'HomeSliderItem') {
						return {
							type: 'HomeSliderItem'
						};
					}
				}
			}
		),
		view: defaultType.view.extend({
			onRender() {
				if(this.$el.find('.custom').length > 0) {
					this.model.handleTraitChange();
				}
			}
		})
	});
});
